import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroupXp from "./Button";

export default function Buttons({ changeXp, reset, sendMessage }) {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Couler</Typography>
            <ButtonGroupXp label="Sloop" onClick={changeXp} value={500} />
            <ButtonGroupXp label="Brigantin" onClick={changeXp} value={1000} />
            <ButtonGroupXp label="Gallion" onClick={changeXp} value={1500} />
            <ButtonGroupXp
              label="Squelette Sloop"
              onClick={changeXp}
              value={500}
            />
            <ButtonGroupXp
              label="Squelette Gallion"
              onClick={changeXp}
              value={1000}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Flag</Typography>
            <ButtonGroupXp label="Flag 1" onClick={changeXp} value={100} />
            <ButtonGroupXp label="Flag 2" onClick={changeXp} value={200} />
            <ButtonGroupXp label="Flag 3" onClick={changeXp} value={300} />
            <ButtonGroupXp label="Flag 4" onClick={changeXp} value={400} />
            <ButtonGroupXp label="Flag 5" onClick={changeXp} value={500} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Voler</Typography>
            <ButtonGroupXp label="Fort" onClick={changeXp} value={3000} />
            <ButtonGroupXp label="Athena" onClick={changeXp} value={4000} />
            <ButtonGroupXp
              label="FDD + Athena"
              onClick={changeXp}
              value={6000}
            />
            <ButtonGroupXp label="FDF" onClick={changeXp} value={5000} />
            <ButtonGroupXp
              label="Légende du voile + Athena"
              onClick={changeXp}
              value={5000}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Faire</Typography>
            <ButtonGroupXp label="Mégalodon" onClick={changeXp} value={500} />
            <ButtonGroupXp label="Kraken" onClick={changeXp} value={2000} />
            <ButtonGroupXp
              label="Event Bateau"
              onClick={changeXp}
              value={1750}
            />
            <ButtonGroupXp label="FlameHeart" onClick={changeXp} value={1750} />
            <ButtonGroupXp label="Tornade" onClick={changeXp} value={1750} />
            <ButtonGroupXp
              label="Fort Classique"
              onClick={changeXp}
              value={1750}
            />
            <ButtonGroupXp
              label="Fort des Damnés"
              onClick={changeXp}
              value={2000}
            />
            <ButtonGroupXp
              label="Fort des Fortunes"
              onClick={changeXp}
              value={2500}
            />
            <ButtonGroupXp label="Fable" onClick={changeXp} value={6000} />
            <ButtonGroupXp label="Quête" onClick={changeXp} value={4000} />
            <ButtonGroupXp
              label="Serveur viewer"
              onClick={changeXp}
              value={1000}
            />
            <br />
            <ButtonGroupXp label="Gold" onClick={changeXp} value={1000} />
            <ButtonGroupXp
              label="Baril sur le bateau !"
              onClick={changeXp}
              value={250}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Arène PVP</Typography>
            <ButtonGroupXp label="1ère place" onClick={changeXp} value={2500} />
            <ButtonGroupXp label="2ème place" onClick={changeXp} value={2000} />
            <ButtonGroupXp label="3ème place" onClick={changeXp} value={1000} />
            <ButtonGroupXp
              label="Vente coffre"
              onClick={changeXp}
              value={500}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Coffre des Escrocs</Typography>
            <ButtonGroupXp
              label="Coffre des Escrocs"
              onClick={changeXp}
              value={250}
            />
            <ButtonGroupXp
              label="Coffre des Escrocs"
              onClick={changeXp}
              value={500}
            />
            <ButtonGroupXp
              label="Coffre des Escrocs"
              onClick={changeXp}
              value={1000}
            />
            <ButtonGroupXp
              label="Coffre des Escrocs"
              onClick={changeXp}
              value={1500}
            />
          </Paper>
        </Grid>
      </Grid>

      <Button
        className={classes.reset}
        variant="contained"
        startIcon={<DeleteIcon />}
        onClick={reset}
      >
        reset
      </Button>
    </>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    paper: { padding: theme.spacing(2) },
    reset: {
      position: "absolute",
      right: theme.spacing(1),
      bottom: theme.spacing(1),
    },
  };
});
