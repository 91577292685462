import React, { useEffect, useState, useCallback, useRef } from "react";
import useWebSocket from "react-use-websocket";
import { useAuth } from "../../hooks/useAuth";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import Participant from "./Participant";
import Classement from "./Classement";
import Controls from "./Controls";
import Poll from "./Poll";

import { fetchLeaderBoard } from "../../../services/api";
import { createPoll, getPollsStatus } from "../../../services/apiTwitch";
import { withConfiguration } from "../../../services/config";

export default function LeaderBoard() {
  const classes = useStyles();
  const didUnmount = useRef(false);
  const { authTwitch } = useAuth();

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    withConfiguration((config) => config["ws-perfect-pirate"]),
    {
      shouldReconnect: () => {
        return didUnmount.current === false;
      },
      retryOnError: true,
      reconnectAttempts: 60,
      reconnectInterval: 1000,
    }
  );
  const [pollId, setPollId] = useState();
  const [leaderboard, setLeaderBoard] = useState([]);
  const [currentParticipant, setCurrentParticipant] = useState();
  const [currentPosition, setCurrentPosition] = useState();
  const currentParticipantId = currentParticipant?.id;

  const initFetchLeaderBoard = useCallback(async () => {
    try {
      const result = await fetchLeaderBoard();
      setLeaderBoard(result);
    } catch (e) {}
  }, []);

  const initPoll = useCallback(async () => {
    try {
      const { data } = await getPollsStatus(
        authTwitch.access_token,
        authTwitch.broadcaster.id
      );
      const runningPoll = data.find((poll) => poll.status === "ACTIVE");
      if (runningPoll) {
        setPollId(runningPoll.id);
      }
    } catch (e) {}
  }, [authTwitch]);

  useEffect(() => {
    initFetchLeaderBoard();
  }, [initFetchLeaderBoard]);

  useEffect(() => {
    initPoll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastJsonMessage) {
      try {
        if (lastJsonMessage.action === "save") {
          const newLeaderBoard = lastJsonMessage.data;
          setLeaderBoard(newLeaderBoard);
          const [participant, index] = newLeaderBoard.reduce(
            (acc, p, index) =>
              p.id === currentParticipantId ? [p, index] : acc,
            []
          );
          setCurrentParticipant(participant);
          setCurrentPosition(index + 1);
        } else {
          const { participant, position } = lastJsonMessage.data;
          setCurrentParticipant(participant);
          setCurrentPosition(position);
        }
      } catch (e) {
        console.log("error during websocket reception", e);
      }
    }
  }, [lastJsonMessage, currentParticipantId]);

  const changeParticipant = (participant, position) => {
    sendJsonMessage({ action: "changeCurrent", participant, position });
  };

  const managePoll = async () => {
    if (pollId === undefined) {
      try {
        const body = {
          broadcaster_id: authTwitch.broadcaster.id,
          title: `Quelle note attribuez vous à ${currentParticipant.gamertag}`,
          choices: [
            { title: "1" },
            { title: "2" },
            { title: "3" },
            { title: "4" },
            { title: "5" },
          ],
          duration: 180,
        };
        const { data } = await createPoll(authTwitch.access_token, body);

        setPollId(data[0].id);
      } catch (e) {
        console.log(e);
      }
    } else {
      setPollId(undefined);
    }
  };

  return (
    <div className={classes.perfectpirate}>
      <div className={classes.leaderboardOut}>
        <h1 className={classes.title}>Événement : The Perfect Pirate</h1>
        <Divider className={classes.divider} />
        <Grid container className={classes.leaderboardIn}>
          <Grid item xs={4} className={classes.participant}>
            {currentParticipant && (
              <Participant
                participant={currentParticipant}
                position={currentPosition}
              />
            )}
          </Grid>
          <Grid item xs={8} className={classes.classement}>
            <Classement
              leaderboard={leaderboard}
              changeParticipant={changeParticipant}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.soloBlocs}>
        <div className={classes.soloParticipant}>
          <h1 className={classes.titleSolo}>Événement : The Perfect Pirate</h1>
          <Divider className={classes.dividerSolo} />
          {currentParticipant && (
            <Participant
              participant={currentParticipant}
              position={currentPosition}
              solo={true}
            />
          )}
        </div>
        <div className={classes.poll}>
          <h1 className={classes.titleSolo}>Événement : The Perfect Pirate</h1>
          <Divider className={classes.dividerSolo} />
          {currentParticipant && pollId && (
            <Poll
              participant={currentParticipant}
              position={currentPosition}
              pollId={pollId}
              setPollId={setPollId}
            />
          )}
        </div>
      </div>

      <div className={classes.controls}>
        {currentParticipant && (
          <Controls
            key={currentParticipantId}
            participant={currentParticipant}
            saveNotes={sendJsonMessage}
            managePoll={managePoll}
            pollId={pollId}
          />
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    perfectpirate: {
      margin: theme.spacing(2),
      fontFamily: "tilting",
    },
    leaderboardOut: {
      width: 1090,
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(4, 0, 4, 4),
      background: `100%/100% no-repeat url(${require(`../assets/fond-gris-60.png`)})`,
    },
    leaderboardIn: {
      display: "flex",
      flexDirection: "row",
    },
    title: {
      margin: 0,
      paddingLeft: theme.spacing(2),
      color: "#e7e3d2",
    },
    divider: {
      background: `100%/100% no-repeat url(${require(`../assets/barre-1.png`)})`,
      height: 5,
      margin: theme.spacing(1, 4, 1, 0),
    },
    participant: { display: "flex" },
    classement: {
      display: "flex",
      height: 440,
      paddingRight: theme.spacing(4),
    },
    list: {
      display: "flex",
    },
    position: { minWidth: 100 },
    gamertag: { flex: "1 1 auto" },
    controls: {
      margin: theme.spacing(2, 2),
      padding: theme.spacing(0, 0, 0, 4),
    },
    titleSolo: {
      textAlign: "center",
      paddingTop: theme.spacing(3),
      color: "#e7e3d2",
      margin: 0,
    },
    soloBlocs: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
    soloParticipant: {
      display: "flex",
      flexDirection: "column",
      width: 460,
      height: 570,
      background: `100%/100% no-repeat url(${require(`../assets/fond-view1-2.png`)})`,
    },
    poll: {
      display: "flex",
      flexDirection: "column",
      width: 460,
      height: 571,
      background: `100%/100% no-repeat url(${require(`../assets/fond-view1-2.png`)})`,
    },
    dividerSolo: {
      textAlign: "center",
      background: `100%/100% no-repeat url(${require(`../assets/barre-2.png`)})`,
      height: 5,
      margin: theme.spacing(0, 2, 2, 2),
    },
  };
});
