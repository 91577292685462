import { withConfiguration } from "./config";
import { get, post } from "./fetcher";

export const getBroadcasterId = async (oAuth, userName) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config.twitch.api}/users${
      userName ? `?login=${userName}` : ""
    }`;
    return get(endpoint, undefined, {
      Authorization: `Bearer ${oAuth}`,
      "client-id": config.twitch.clientId,
    });
  });
};

export const createPoll = async (oAuth, body) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config.twitch.api}/polls`;
    return post(endpoint, body, {
      Authorization: `Bearer ${oAuth}`,
      "client-id": config.twitch.clientId,
    });
  });
};
export const getPollsStatus = async (oAuth, broadcasterId) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config.twitch.api}/polls?broadcaster_id=${broadcasterId}`;
    return get(endpoint, undefined, {
      Authorization: `Bearer ${oAuth}`,
      "client-id": config.twitch.clientId,
    });
  });
};

export const getPollStatus = async (oAuth, broadcasterId, id) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config.twitch.api}/polls?broadcaster_id=${broadcasterId}&id=${id}`;
    return get(endpoint, undefined, {
      Authorization: `Bearer ${oAuth}`,
      "client-id": config.twitch.clientId,
    });
  });
};

export const getClips = async (oAuth, broadcasterId, payload) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config.twitch.api}/clips`;
    return get(
      endpoint,
      { broadcaster_id: broadcasterId, ...payload },
      {
        Authorization: `Bearer ${oAuth}`,
        "client-id": config.twitch.clientId,
      }
    );
  });
};

export const getGames = async (oAuth, payload) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config.twitch.api}/games`;
    return get(endpoint, payload, {
      Authorization: `Bearer ${oAuth}`,
      "client-id": config.twitch.clientId,
    });
  });
};
