import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

export default function Note({ note, title }) {
  const classes = useStyles();

  return (
    <div className={classes.bloc}>
      <div className={classes.note}>{note || "~"}/5</div>
      <Divider className={classes.divider} />
      <div className={classes.title}>{title}</div>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    bloc: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    note: {
      textAlign: "center",
      color: "#f1ad31",
      fontSize: 35,
    },
    title: {
      color: "#e7e3d2",
      fontSize: 20,
    },
    divider: {
      background: `100%/150% no-repeat url(${require(`../assets/barre-4.png`)})`,
      height: 2,
      margin: theme.spacing(0.5, 0, 1, 0),
      width: 150,
    },
  };
});
