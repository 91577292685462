import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Level({
  currentLvl,
  currentXp,
  index,
  level,
  full,
  isSolo,
  maxLevel,
  animateXp,
  modifiedXp,
  animationCallback,
  className,
}) {
  const { label, xp, img, free, subs } = level;
  const classes = useStyles();
  const lvlOk = currentLvl > index;
  const completion = lvlOk
    ? 100
    : currentLvl < index
    ? 0
    : (100 * currentXp) / xp;

  useEffect(() => {
    if (animateXp) {
      setTimeout(animationCallback, 2500);
    }
  }, [animateXp, animationCallback]);

  return (
    <div
      className={className}
      style={
        isSolo &&
        (maxLevel && currentLvl === index + 1
          ? {}
          : currentLvl !== index
          ? { position: "absolute", opacity: 0 }
          : {})
      }
    >
      <div className={classes.lvl} style={{ zIndex: 1 }}>
        {img ? (
          <img
            className={`${classes.img} ${
              currentLvl < index && classes.opacity
            }`}
            src={require(`../../../public/img/passxp/lvls/${img}`)}
            alt={`level${label}`}
          />
        ) : (
          label
        )}
        <CircularProgress
          className={classes.progress}
          variant="static"
          value={completion}
          size={131}
          thickness={3}
        />
      </div>
      {isSolo && (
        <div style={{ position: "relative", height: 150, zIndex: 2 }}>
          <div
            className={classes.animateXp}
            style={animateXp ? { opacity: 1, animation: "960ms bounce" } : {}}
          >
            <div className={classes.solo}>
              {modifiedXp.xp > 0 && "+"}
              {modifiedXp.xp} xp <br />
              {modifiedXp.label}
            </div>
          </div>

          <div
            className={classes.xp}
            style={animateXp ? { visibility: "hidden", opacity: 0 } : {}}
          >
            <div className={classes.solo}>
              {maxLevel ? level.xp : currentXp}/{level.xp} xp
            </div>
          </div>
        </div>
      )}
      {full && (
        <>
          <div className={`${classes.rewards} ${free ? classes.free : ""}`}>
            {free && (
              <>
                <div
                  className={`${classes.reward} ${
                    !lvlOk && classes.hideReward
                  }`}
                >
                  <img
                    className={classes.imgReward}
                    src={require(`../../../public/img/passxp/free/${free.img}`)}
                    alt={`free${label}`}
                  />
                  <div
                    className={classes.labelReward}
                    style={{ fontSize: free.textSize || "1em" }}
                  >
                    {free.label}
                  </div>
                </div>
                <div
                  className={`${classes.questionMark} ${
                    lvlOk && classes.hideQuestionMark
                  }`}
                >
                  ?
                </div>
              </>
            )}
          </div>
          <div className={`${classes.rewards} ${classes.subs}`}>
            {subs && (
              <>
                <div
                  className={`${classes.reward} ${
                    !lvlOk && classes.hideReward
                  }`}
                >
                  <img
                    className={classes.imgReward}
                    src={require(`../../../public/img/passxp/subs/${subs.img}`)}
                    alt={`free${label}`}
                  />
                  <div
                    className={classes.labelReward}
                    style={{ fontSize: subs.textSize || "1em" }}
                  >
                    {subs.label}
                  </div>
                </div>
                <div
                  className={`${classes.questionMark} ${
                    lvlOk && classes.hideQuestionMark
                  }`}
                >
                  ?
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

const background = "background.png";
const useStyles = makeStyles((theme) => {
  return {
    lvl: {
      position: "relative",
      height: 150,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.primary.main,
      fontWeight: "500",
    },
    img: {
      zIndex: 2,
      width: 120,
    },
    rewards: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 200,
      height: 184,
      marginBottom: 5,
      fontFamily: "Pirate",
      fontSize: 20,
    },
    reward: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 240ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    hideReward: {
      transform: "scale(0,0)",
    },
    imgReward: {
      zIndex: 2,
      width: "60%",
    },
    labelReward: {
      textAlign: "center",
    },
    progress: {
      position: "absolute",
      color: "#00C3DE",
    },
    free: {
      color: "white",
      background: `100%/100% no-repeat url(${require(`../../../public/img/passxp/free/${background}`)})`,
    },
    subs: {
      color: "black",
      background: `100%/100% no-repeat url(${require(`../../../public/img/passxp/subs/${background}`)})`,
    },
    opacity: {
      opacity: 0.95,
      filter: "grayscale(1)",
    },
    questionMark: {
      position: "absolute",
      top: "50%",
      transform: "translate(0, -50%)",
      height: 105,
      fontSize: 80,
      transition: "opacity 240ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    hideQuestionMark: {
      opacity: 0,
    },
    xp: {
      position: "absolute",
      width: "100%",
      opacity: 1,
      transition: "opacity 240ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    animateXp: {
      position: "absolute",
      width: "100%",
      opacity: 0,
      transition: "opacity 240ms cubic-bezier(0.4, 0, 0.2, 1)",
      animationTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    },
    solo: {
      fontFamily: "Pirate",
      fontSize: 28,
      textAlign: "center",
      color: "#00C3DE",
      textShadow:
        "-1px 0 #083138, 0 1px #083138, 1px 0 #083138, 0 -1px #083138",
      zIndex: 10,
    },
  };
});
