import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import SlotMachine from "./SlotMachine";

export default function JackADit() {
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <Card className={classes.container}>
        <CardHeader
          classes={{ root: classes.header, subheader: classes.subheader }}
          title="Défi principal"
        />
        <CardContent>
          <SlotMachine
            code="principal"
            defaultItems={[
              "Jouer sans botte",
              "Zoom la caméra x5",
              "kill = STOP 5 sec",
              "Sensi max",
              "Zero overlay",
              "Zero mythique",
              "Random Champ",
              "Zero ult",
              "Pas de fast cast",
              "Les Viewers décident",
              "Explique à un noob",
            ]}
          />
        </CardContent>
      </Card>
      <Card className={classes.container}>
        <CardHeader
          classes={{ root: classes.header, subheader: classes.subheader }}
          title="Défi secondaire"
        />
        <CardContent>
          <SlotMachine code="secondaire" defaultItems={["test"]} />
        </CardContent>
      </Card>
      <div></div>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    app: {
      marginTop: 40,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "20px",
      flexWrap: "wrap",
    },
    container: {
      margin: theme.spacing(2),
    },
    header: {
      backgroundColor: theme.palette.background.dark.main,
      color: theme.palette.customText.primary.main,
    },
    subheader: {
      color: theme.palette.customText.secondary.main,
    },
  };
});
