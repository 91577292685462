import { withConfiguration } from "./config";
import { get, post } from "./fetcher";

export const authenticate = async (login, password) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/auth`;
    return post(endpoint, { login, password });
  });
};

export const fetchPassXpParams = async () => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/passxp/getParams`;
    return get(endpoint);
  });
};

export const fetchJackADitDefis = async (code) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/jack-a-dit/get-defis?code=${code}`;
    return get(endpoint);
  });
};

export const updateParams = async (currentLvl, currentXp) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/passxp/setParams`;
    return post(endpoint, { currentLvl, currentXp });
  });
};

export const fetchViewed = async (startAt, endAt, OAuth) => {
  return withConfiguration(async (config) => {
    return post("https://gql.twitch.tv/gql", buildBody(startAt, endAt), {
      Authorization: `OAuth ${OAuth}`,
      "Client-Id": "kimne78kx3ncx6brgo4mv6wki5h1ko",
    });
  });
};

export const getTwitchAuth = async (code) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/twitch/auth`;
    return post(endpoint, { code });
  });
};

export const getTwitchAppAccessAuth = async () => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/twitch/app-access-auth`;
    return post(endpoint);
  });
};

export const fetchLeaderBoard = async () => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/the-perfect-pirate/leaderboard`;
    return get(endpoint);
  });
};

export const saveNotes = async (payload) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/the-perfect-pirate/save-notes`;
    return post(endpoint, payload);
  });
};
export const fetchAllBeyondClick = async (payload) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/beyond/get-click`;
    return get(endpoint);
  });
};

const buildBody = (startAt, endAt) => [
  {
    operationName: "ConcurrentViewersTimeseriesStats_StreamSummary",
    variables: {
      channelID: "30073531",
      timeZone: "Europe/Paris",
      granularity: "FIVE_MINUTE",
      startAt,
      endAt,
    },
    extensions: {
      persistedQuery: {
        version: 1,
        sha256Hash:
          "bd4216723fee3a2fe7068107e1d288fda103c2642a95244bdea4628326d90b3f",
      },
    },
  },
];
