import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchViewed } from "../../services/api";

export default function ViewingTime() {
  const classes = useStyles();
  const [result, setResult] = useState();
  const [startAt, setStartAt] = useState(localStorage.startAt || "");
  const [endAt, setEndAt] = useState(localStorage.endAt || "");
  const [OAuth, setOAuth] = useState(localStorage.OAuth || "");
  const [loading, setLoading] = useState(false);

  const change = (name, fct) => (e) => {
    const value = e.target.value;

    localStorage[name] = value;
    fct(value);
  };

  const getViewed = async () => {
    setLoading(true);
    const usedStartAt = new Date(startAt);
    const usedEndAt = new Date(endAt);
    const usedOAuth = OAuth || "xc3sv90xlc64qsy3q6zav6kxj044po";
    usedEndAt.setMinutes(usedEndAt.getMinutes() + 1);

    try {
      const result = await fetchViewed(usedStartAt, usedEndAt, usedOAuth);
      const averagesViewers =
        result[0].data.user.timeseriesStats.averageViewers;
      const minutesViewed = averagesViewers.reduce((acc, averageViewers) => {
        return acc + averageViewers.value * 5;
      }, 0);

      const hoursRounded = (minutesViewed / 60).toFixed(2);
      const minutes = minutesViewed % 60;

      setResult(
        `${parseInt(hoursRounded)} heures et ${parseInt(
          minutes
        )} minutes (${hoursRounded})`
      );
    } catch (e) {}
    setLoading(false);
  };

  return (
    <Card className={classes.container}>
      <CardHeader
        classes={{
          root: classes.header,
          subheader: classes.subheader,
        }}
        title="Statistiques"
        subheader="Février 2021"
      />
      <CardContent className={classes.content}>
        <div className={classes.stat}>
          <div className={classes.title}>Temps de visionnage total (live)</div>
          <div className={classes.fields}>
            <div className={classes.dates}>
              <TextField
                className={classes.field}
                label="Début du stream"
                type="datetime-local"
                value={startAt}
                onChange={change("startAt", setStartAt)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                className={classes.field}
                label="Fin du stream"
                type="datetime-local"
                value={endAt}
                onChange={change("endAt", setEndAt)}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <TextField
              className={classes.field}
              label="OAuth"
              value={OAuth}
              onChange={change("OAuth", setOAuth)}
            />
            <Button
              style={{ marginBottom: 10 }}
              variant="contained"
              color="primary"
              size="small"
              onClick={getViewed}
              className={classes.button}
            >
              {loading ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                "Calculer"
              )}
            </Button>
            <div className={classes.result}>{result}</div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: theme.spacing(2),
    },
    header: {
      backgroundColor: theme.palette.background.dark.main,
      color: theme.palette.customText.primary.main,
    },
    subheader: {
      color: theme.palette.customText.secondary.main,
    },
    title: {
      fontWeight: 500,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: theme.palette.customText.primary.main,
    },
    result: {
      fontWeight: 500,
    },
    stat: {
      marginLeft: theme.spacing(2),
    },
    fields: {
      display: "flex",
      alignItems: "center",
    },
    field: {
      marginRight: theme.spacing(2),
      width: 200,
    },
    content: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    button: { height: 35, width: 90, marginRight: theme.spacing(2) },
  };
});
