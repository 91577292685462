import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

function ButtonXp({ onClick, label, minus, className }) {
  return (
    <Button
      style={{ marginBottom: 10 }}
      variant="contained"
      color={minus ? "default" : "primary"}
      size="small"
      onClick={onClick}
      className={className}
    >
      {label}
    </Button>
  );
}

export default function ButtonGroupXp({ onClick, label, value }) {
  const classes = useStyles();

  return (
    <div>
      <ButtonXp
        label={`- ${value}`}
        onClick={onClick(-value, label)}
        className={classes.minus}
        minus
      />
      <ButtonXp
        label={`${label} (+${value})`}
        onClick={onClick(value, label)}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    minus: {
      marginRight: 5,
    },
  };
});
