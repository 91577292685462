import React from "react";
import ViewingTime from "./ViewingTime";
import Beyond from "./Beyond";

export default function PassXp() {
  return (
    <div>
      <ViewingTime />
      <Beyond />
    </div>
  );
}
