import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const background = "background.png";
const barre = "barre-background.png";
const logo = "logo.png";
export default function Bloc({
  currentLvlMaxXp,
  currentTitle,
  currentLvl,
  currentXp,
  maxLevel,
  maxLevelXp,
  animateXp,
  modifiedXp,
  animationCallback,
  className,
}) {
  const classes = useStyles();

  const completion = maxLevel ? 100 : (100 * currentXp) / currentLvlMaxXp;

  useEffect(() => {
    if (animateXp) {
      setTimeout(animationCallback, 4000);
    }
  }, [animateXp, animationCallback]);

  return (
    <div className={classes.container}>
      <img
        className={classes.background}
        src={require(`../../../public/img/passxpv2/bloc/${background}`)}
        alt="background"
      />
      <div className={classes.barre}>
        <div className={classes.lvls}>
          <span className={classes.lvlLeft}>
            {maxLevel ? currentLvl - 1 : currentLvl}
          </span>
          <span className={classes.lvlRight}>
            {maxLevel ? currentLvl : currentLvl + 1}
          </span>
        </div>
        <div
          className={classes.animateXp}
          style={
            animateXp || maxLevel
              ? { opacity: 1, animation: "960ms frombot" }
              : {}
          }
        >
          {maxLevel ? (
            <div className={classes.solo}>Escroc pass terminé !</div>
          ) : (
            <div className={classes.solo}>
              {modifiedXp.xp > 0 && "+"}
              {modifiedXp.xp} xp - {modifiedXp.label}
            </div>
          )}
        </div>
        <div
          className={classes.currentXp}
          style={{ width: `calc(${completion}% - 20px)` }}
        ></div>
        <img
          src={require(`../../../public/img/passxpv2/bloc/${barre}`)}
          alt="barre"
        />
      </div>
      <div className={classes.title}>
        <img
          className={classes.logo}
          src={require(`../../../public/img/passxpv2/bloc/${logo}`)}
          alt="logo"
        />
        <div className={classes.textes}>
          <span className={classes.texte1}>RENOMÉE ACTUELLE :</span>
          <span className={classes.texte2}>{currentTitle}</span>
        </div>
      </div>
      <div className={classes.currentXpTxt}>
        {maxLevel
          ? `${maxLevelXp} / ${maxLevelXp} xp`
          : `${currentXp} / ${currentLvlMaxXp} xp`}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      position: "relative",
    },
    background: {},
    barre: {
      position: "absolute",
      bottom: 30,
      left: 39,
      display: "flex",
      flexDirection: "column",
    },
    title: {
      position: "absolute",
      top: 0,
      left: 20,
      display: "flex",
      flexDirection: "row",
    },
    lvls: {
      display: "flex",
      justifyContent: "space-between",
    },
    lvlLeft: {
      fontSize: 40,
      fontFamily: "tilting",
      color: "#ffffff",
    },
    lvlRight: {
      fontSize: 40,
      fontFamily: "tilting",
      color: "#ffffff",
    },
    currentXp: {
      position: "absolute",
      bottom: 10,
      left: 10,
      right: 10,
      height: 13,
      backgroundColor: "#95701d",
      transition: "all 540ms ease",
    },
    currentXpTxt: {
      position: "absolute",
      bottom: 6,
      right: 50,
      fontSize: 24,
      fontFamily: "tilting",
      color: "#ffffff",
      transition: "all 540ms ease",
    },
    textes: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "tilting",
      color: "#ffffff",
    },
    texte1: {
      marginTop: 30,
      fontSize: 24,
    },
    texte2: {
      marginTop: 35,
      fontSize: 24,
    },
    animateXp: {
      position: "absolute",
      width: "100%",
      opacity: 0,
      transition: "opacity 240ms cubic-bezier(0.4, 0, 0.2, 1)",
      animationTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    },
    solo: {
      fontFamily: "tilting",
      fontSize: 28,
      textAlign: "center",
      color: "#ffffff",
      zIndex: 10,
    },
  };
});
