import React, { useState, useEffect, useRef } from "react";
import useWebSocket from "react-use-websocket";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Level from "./Level";
import Buttons from "./Buttons";

import { withConfiguration } from "../../services/config";
import { fetchPassXpParams } from "../../services/api";

import LEVELS from "./levels.json";

const head = "head.png";

export default function PassXp() {
  const didUnmount = useRef(false);
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    withConfiguration((config) => config["ws-escroc-pass"]),
    {
      shouldReconnect: () => {
        return didUnmount.current === false;
      },
      retryOnError: true,
      reconnectAttempts: 60,
      reconnectInterval: 1000,
    }
  );

  const [currentXp, setCurrentXp] = useState(0);
  const [currentLvl, setCurrentLvl] = useState(0);
  const [animateXp, setAnimateXp] = useState(false);
  const [modifiedXp, setModifiedXp] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    _fetchParams();
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (lastJsonMessage) {
      try {
        if (lastJsonMessage) {
          const { xp, label, newLvl, newXp } = lastJsonMessage;
          if (label) {
            setAnimateXp(true);
            setModifiedXp({ xp, label });
          }
          setCurrentLvl(newLvl);
          setCurrentXp(newXp);
        }
      } catch (e) {
        console.log("error during websocket reception", e);
      }
    }
  }, [lastJsonMessage]);

  const _fetchParams = async () => {
    const { currentLvl, currentXp } = await fetchPassXpParams();
    setCurrentLvl(currentLvl);
    setCurrentXp(currentXp);
  };

  const changeXp = (xp, label) => async () => {
    const [newLvl, newXp] = calculLvlAndXp(currentLvl, currentXp, xp);

    setAnimateXp(true);
    setModifiedXp({ xp, label });

    setCurrentLvl(newLvl);
    setCurrentXp(newXp);

    sendJsonMessage({ xp, label, newLvl, newXp });
  };

  const calculLvlAndXp = (lvl, xp, addedXp) => {
    if (lvl >= LEVELS.length && addedXp >= 0) return [LEVELS.length, 0];
    if (xp + addedXp < 0) {
      if (lvl - 1 < 0) return [0, 0];
      return calculLvlAndXp(lvl - 1, LEVELS[lvl - 1].xp, xp + addedXp);
    }
    if (xp + addedXp >= LEVELS[lvl].xp) {
      return calculLvlAndXp(lvl + 1, 0, addedXp - (LEVELS[lvl].xp - xp));
    }
    return [lvl, xp + addedXp];
  };

  const reset = () => {
    setCurrentLvl(0);
    setCurrentXp(0);
    sendJsonMessage({ newLvl: 0, newXp: 0 });
  };

  const animationCallback = () => setAnimateXp(false);

  return (
    <Card className={classes.container}>
      <CardHeader
        classes={{
          root: classes.header,
          subheader: classes.subheader,
        }}
        title="Pass XP"
        subheader="Avril 2020"
      />
      <CardContent className={classes.content}>
        <div className={classes.table}>
          <div className={classes.levels}>
            {LEVELS.map((level, index) => (
              <Level
                key={index}
                className={classes.level}
                index={index}
                level={level}
                currentLvl={currentLvl}
                currentXp={currentXp}
                full={false}
              />
            ))}
          </div>
        </div>
        <div className={classes.table}>
          <div className={classes.pass}>
            <div className={classes.lineHeader}>
              <div className={classes.line1}></div>
              <div className={classes.line}>
                <img
                  className={classes.img}
                  src={require(`../../../public/img/passxp/free/${head}`)}
                  alt="free-head"
                />
              </div>
              <div className={classes.line}>
                <img
                  className={classes.img}
                  src={require(`../../../public/img/passxp/subs/${head}`)}
                  alt="subs-head"
                />
              </div>
            </div>
            {LEVELS.map((level, index) => (
              <Level
                className={classes.levelFull}
                key={index}
                index={index}
                level={level}
                currentLvl={currentLvl}
                currentXp={currentXp}
                full={true}
              />
            ))}
          </div>
        </div>
        <div className={classes.table2}>
          <div className={classes.levels}>
            {LEVELS.map((level, index) => (
              <Level
                className={classes.levelSolo}
                key={index}
                index={index}
                level={level}
                currentLvl={currentLvl}
                currentXp={currentXp}
                maxLevel={currentLvl === LEVELS.length}
                animateXp={animateXp}
                modifiedXp={modifiedXp}
                animationCallback={animationCallback}
                isSolo
              />
            ))}
          </div>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <Buttons changeXp={changeXp} reset={reset} />
      </CardActions>
    </Card>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: theme.spacing(2),
    },
    header: {
      backgroundColor: theme.palette.background.dark.main,
      color: theme.palette.customText.primary.main,
    },
    subheader: {
      color: theme.palette.customText.secondary.main,
    },
    content: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    table: {
      display: "flex",
      marginTop: theme.spacing(2),
      overflowX: "auto",
    },
    table2: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
    },
    levels: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "blue",
      padding: theme.spacing(2),
    },
    level: {
      margin: theme.spacing(2),
      transition: "opacity 840ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    levelFull: {
      margin: 5,
    },
    levelSolo: {
      margin: theme.spacing(2),
      width: 280,
      transition: "opacity 840ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    pass: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      backgroundColor: "blue",
      padding: theme.spacing(2),
    },
    lineHeader: {
      flexDirection: "column",
      margin: 5,
    },
    line1: {
      height: 150,
    },
    line: {
      height: 184,
      marginBottom: 5,
    },
    img: { height: "100%" },
    actions: {
      position: "relative",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "flex-start",
    },
  };
});
