import React, { useEffect, useCallback } from "react";
import { getTwitchAuth } from "../../services/api";
import { useAuth } from "../hooks/useAuth";

import LeaderBoard from "./LeaderBoard";

// const secret = "adzeczvrjnfezofbezonfzoef";

const getParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export default function ThePerfectPirate() {
  const { authTwitch, loginTwitch } = useAuth();
  const _fetchTwitchConnection = useCallback(async () => {
    const code = getParam("code");
    if (authTwitch === undefined && code !== undefined && code !== null) {
      try {
        const result = await getTwitchAuth(code);
        loginTwitch(result);
      } catch (e) {}
    }
  }, [loginTwitch, authTwitch]);

  useEffect(() => {
    _fetchTwitchConnection();
  }, [_fetchTwitchConnection]);

  return (
    <div style={{ margin: 20 }}>
      <LeaderBoard />
    </div>
  );
}
