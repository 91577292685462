import { startOfMonth, addMonths, isAfter, isEqual } from "date-fns";

export const buildMonths = (start, end) => {
  let startMonth = startOfMonth(start);
  const months = [];
  while (isAfter(startMonth, end) || isEqual(startMonth, end)) {
    months.push(startMonth);
    const newMonth = addMonths(startMonth, -1);
    startMonth = newMonth;
  }
  return months;
};

export const MONTHS_NAMES = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];
