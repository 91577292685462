import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { fetchAllBeyondClick } from "../../services/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import format from "date-fns/format";
import { fr } from "date-fns/locale";

export default function Beyond() {
  const classes = useStyles();
  const [clicks, setClicks] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const _fetchStats = React.useCallback(async () => {
    setLoading(true);
    try {
      const clicks = await fetchAllBeyondClick();
      setClicks(clicks);
    } catch (e) {}
    setLoading(false);
  }, []);

  React.useEffect(() => {
    _fetchStats();
  }, [_fetchStats]);

  return (
    <Card className={classes.container}>
      <CardHeader
        classes={{
          root: classes.header,
          subheader: classes.subheader,
        }}
        title="Beyond"
      />
      <CardContent className={classes.content}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Jour</TableCell>
                <TableCell align="right">Nombre de cliques</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clicks.map(({ date, click }, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {format(new Date(date), "EEEE d MMMM yyyy", {
                        locale: fr,
                      })}
                    </TableCell>
                    <TableCell align="right">{click}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: theme.spacing(2),
    },
    header: {
      backgroundColor: theme.palette.background.dark.main,
      color: theme.palette.customText.primary.main,
    },
    content: { width: 400 },

    subheader: {
      color: theme.palette.customText.secondary.main,
    },
  };
});
