import React from "react";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";

export default function Classement({ leaderboard, changeParticipant }) {
  const classes = useStyles();

  const _changeParticipant = (participant, position) => () => {
    changeParticipant(participant, position);
  };

  return (
    <div className={classes.classement}>
      <div className={classes.title}>Classement</div>
      <Divider className={classes.divider} />
      <div className={classes.list}>
        <div className={classes.headline}>
          <div className={classes.headposition}>Position</div>
          <div className={classes.headgamertag}>Gamertag</div>
          <div className={classes.headpoints}>Points</div>
        </div>
        <div className={classes.lines}>
          {leaderboard.map((participant, index) => (
            <div
              className={classes.line}
              key={index}
              onClick={_changeParticipant(participant, index + 1)}
            >
              <div className={classes.position}>
                <img
                  className={classes.etoile}
                  src={require("../assets/etoile.png")}
                  alt="etoile"
                />{" "}
                {index + 1}
              </div>
              <div className={classes.gamertag}>{participant.gamertag}</div>
              <div className={classes.points}>{participant.total}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    classement: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      background: `100%/100% no-repeat url(${require(`../assets/fond-noir-70.png`)})`,
      padding: theme.spacing(1, 2, 1, 1),
    },
    divider: {
      background: `100%/100% no-repeat url(${require(`../assets/barre-2.png`)})`,
      height: 5,
      margin: theme.spacing(1, 2, 2, 2),
    },
    title: {
      color: "#f1ad31",
      fontSize: 26,
      margin: theme.spacing(1, 2, 0, 2),
    },
    list: { padding: theme.spacing(0, 1, 0, 4) },
    lines: {
      height: 320,
      overflow: "auto",
      paddingRight: theme.spacing(1),
    },
    headline: {
      display: "flex",
      color: "#aba491",
      fontSize: 20,
    },
    headposition: {
      minWidth: 150,
      margin: theme.spacing(0, 1),
    },
    headgamertag: { flex: "1 1 auto" },
    headpoints: { paddingRight: theme.spacing(1) },
    line: {
      display: "flex",
      fontSize: 20,
      background: `100%/100% no-repeat url(${require(`../assets/barrenoire.png`)})`,
      margin: theme.spacing(0.5, 0),
      padding: `9px ${theme.spacing(2)}px`,
      cursor: "pointer",
    },
    position: {
      display: "flex",
      alignItems: "center",
      minWidth: 150,
      color: "#f1ad31",
    },
    etoile: { width: 15, marginRight: 5 },
    gamertag: { flex: "1 1 auto", color: "#e7e3d2" },
    points: {
      color: "#e5d674",
    },
  };
});
