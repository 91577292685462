import React from "react";
import Button from "@material-ui/core/Button";

export default function ButtonXp({ onClick, label, color, className }) {
  return (
    <Button
      style={{ marginBottom: 10 }}
      variant="contained"
      color={color}
      size="small"
      onClick={onClick}
      className={className}
    >
      {label}
    </Button>
  );
}
