import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { AuthProvider } from "./components/hooks/useAuth";
import { SnackbarProvider } from "./components/hooks/useSnackbar";

import App from "./components/App";
import "./global.css";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#9147ff", light: "#a970ff", dark: "#772ce8" },
    secondary: { main: "#18181a", light: "#424243", dark: "#0e0e0f" },
    customText: {
      primary: { main: "#9147ff", light: "#a970ff", dark: "#772ce8" },
      secondary: { main: "#efeff1" },
    },
    background: {
      dark: {
        main: "#18181a",
        light: "#424243",
        dark: "#0e0e0f",
        lighter: "#323233",
      },
    },
  },
  typography: { useNextVariants: true },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
