import React, { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import useInterval from "../../hooks/useInterval";

import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

import { getPollStatus } from "../../../services/apiTwitch";

const findChoice = (choices, number) =>
  choices.find((choice) => choice.title === number);

const calculPourcent = (totalVotes, choices, number) =>
  totalVotes > 0
    ? Math.floor((findChoice(choices, number)?.votes / totalVotes) * 100)
    : 0;

const calculTime = (poll) => {
  if (poll) {
    const { started_at, status } = poll;
    if (status !== "ACTIVE") return 0;

    const start = new Date(started_at);
    const upTime = Math.floor((start.getTime() - new Date().getTime()) / 1000);
    return 180 + upTime;
  }
};

const Bar = ({ totalVotes, choices, number }) => {
  const classes = useStyles();
  const pourcent = calculPourcent(totalVotes, choices, number);

  return (
    <div className={classes.barOut} style={{ height: `${pourcent}%` }}>
      <div
        className={classes.barIn}
        style={pourcent < 10 ? { position: "absolute", top: -28 } : {}}
      >
        {pourcent}%
      </div>
    </div>
  );
};

export default function Poll({ participant, pollId, setPollId }) {
  const classes = useStyles();
  const { authTwitch } = useAuth();
  const [poll, setPoll] = useState();

  const totalVotes =
    poll && poll.choices.reduce((acc, choice) => acc + choice.votes, 0);

  const timeRemaining = calculTime(poll);
  console.log(timeRemaining);
  useInterval(
    async () => {
      try {
        const { data } = await getPollStatus(
          authTwitch.access_token,
          authTwitch.broadcaster.id,
          pollId
        );
        const poll = data[0];
        setPoll(poll);
      } catch (e) {}
    },
    pollId || timeRemaining > 0 ? 1000 : null
  );

  return (
    <div className={classes.currentParticipant}>
      <div>
        <h2 className={classes.title}>Sondage chat</h2>
        <Divider className={classes.divider} />
      </div>
      <div className={classes.gamertag}>{participant.gamertag}</div>
      {poll && (
        <div className={classes.results}>
          <div className={classes.bars}>
            <Bar totalVotes={totalVotes} choices={poll.choices} number="1" />
            <Bar totalVotes={totalVotes} choices={poll.choices} number="2" />
            <Bar totalVotes={totalVotes} choices={poll.choices} number="3" />
            <Bar totalVotes={totalVotes} choices={poll.choices} number="4" />
            <Bar totalVotes={totalVotes} choices={poll.choices} number="5" />
          </div>
          <Divider className={classes.divider2} />
          <div className={classes.numbers}>
            <div className={classes.number}>1</div>
            <div className={classes.number}>2</div>
            <div className={classes.number}>3</div>
            <div className={classes.number}>4</div>
            <div className={classes.number}>5</div>
          </div>
          <div className={classes.total}>
            Nombre total de votes : {totalVotes}
            <br />
            {timeRemaining > 0 ? (
              <>
                Temps restant :{" "}
                {Math.floor(timeRemaining / 60) > 0
                  ? `${Math.floor(timeRemaining / 60)} minutes `
                  : ""}
                {timeRemaining % 60} secondes
              </>
            ) : (
              "Sondage terminé !"
            )}
          </div>
          <div className={classes.offset}> </div>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    currentParticipant: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      width: "100%",
      marginTop: theme.spacing(1),
    },
    title: {
      margin: 0,
      fontSize: 30,
      color: "#aba491",
      textShadow:
        "-1px 0 5px #4c4a46, 0 1px 5px #4c4a46, 1px 0 5px #4c4a46, 0 -1px 5px #4c4a46",
    },
    divider: {
      background: `100%/100% no-repeat url(${require(`../assets/barre-3.png`)})`,
      height: 5,
      margin: theme.spacing(0.5, 0),
    },
    divider2: {
      height: 5,
      margin: theme.spacing(1, 0),
      background: `100%/150% no-repeat url(${require(`../assets/barre-2.png`)})`,
    },
    gamertag: {
      fontSize: 50,
      color: "#e7e3d2",
      marginBottom: theme.spacing(2),
    },
    results: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      width: "80%",
    },
    bars: {
      display: "flex",
      flexDirection: "row",
      height: 200,
      alignItems: "flex-end",
    },
    barOut: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "20%",
      background: "#e7e3d2",
      flexDirection: "row",
      margin: theme.spacing(0, 1, 0, 1),
    },
    barIn: {
      fontSize: 25,
      color: "#aba491",
    },
    numbers: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    number: {
      width: "20%",
      textAlign: "center",
      color: "#f1ad31",
      fontSize: 40,
    },
    total: {
      color: "#e7e3d2",
    },
    offset: { height: 48 },
  };
});
