import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

export default function Controls({
  participant,
  saveNotes,
  managePoll,
  pollId,
}) {
  const classes = useStyles();

  const [noteViewers, setNoteViewers] = useState(participant.noteViewers || "");
  const [noteJury, setNoteJury] = useState(participant.noteJury || "");
  const [noteJack, setNoteJack] = useState(participant.noteJack || "");
  const [bonus, setBonus] = useState(participant.bonus || "");

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };
  const saveChange = async () => {
    await saveNotes({
      action: "save",
      id: participant.id,
      noteViewers,
      noteJury,
      noteJack,
      bonus,
    });
  };

  return (
    <Paper className={classes.paper}>
      <h2>
        Modifier les notes :{" "}
        <span className={classes.gamertag}>{participant.gamertag}</span>
      </h2>
      <div className={classes.fields}>
        <TextField
          type="number"
          label="Note Chat"
          value={noteViewers}
          onChange={handleChange(setNoteViewers)}
          onBlur={saveChange}
        />
        <TextField
          type="number"
          label="Note Jury"
          value={noteJury}
          onChange={handleChange(setNoteJury)}
          onBlur={saveChange}
        />
        <TextField
          type="number"
          label="Note Jackdarius"
          value={noteJack}
          onChange={handleChange(setNoteJack)}
          onBlur={saveChange}
        />
        <TextField
          type="number"
          label="Bonus"
          value={bonus}
          onChange={handleChange(setBonus)}
          onBlur={saveChange}
        />
        <Button color="primary" variant="contained" onClick={managePoll}>
          {pollId ? "Arrêter" : "Lancer"} le sondage ({participant.gamertag})
        </Button>
      </div>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    paper: { padding: theme.spacing(2) },
    fields: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      marginTop: theme.spacing(2),
    },
    note: {
      textAlign: "center",
      color: "#f1ad31",
      fontSize: 35,
    },
    title: {
      color: "#e7e3d2",
      fontSize: 20,
    },
    gamertag: { color: "#f1ad31" },
    divider: {
      background: `100%/150% no-repeat url(${require(`../assets/barre-4.png`)})`,
      height: 2,
      margin: theme.spacing(0.5, 0, 1, 0),
      width: 150,
    },
  };
});
