import React, { useState, useEffect, useCallback } from "react";
import { formatRFC3339, startOfMonth, endOfMonth } from "date-fns";
import { buildMonths, MONTHS_NAMES } from "../../services/utils";
import { useAuth } from "../hooks/useAuth";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { getTwitchAppAccessAuth } from "../../services/api";
import { getClips, getGames } from "../../services/apiTwitch";
import Clip from "./Clip";

const LIMIT = new Date(2019, 0);
const RESULT_NB = 20;
const now = new Date();
const months = buildMonths(now, LIMIT);

const buildPayload = (year, month, after, before) => {
  const start = startOfMonth(new Date(year, month));
  const end = endOfMonth(start);
  return {
    first: RESULT_NB,
    started_at: formatRFC3339(start),
    ended_at: formatRFC3339(end),
    after,
    before,
  };
};

export default function Clips() {
  const classes = useStyles();
  const { authTwitchAppAccess, loginTwitchAppAccess } = useAuth();
  const [year, setYear] = useState(now.getFullYear());
  const [month, setMonth] = useState(now.getMonth());
  const [loading, setLoading] = useState(true);
  const [clips, setClips] = useState([]);
  const [games, setGames] = useState({});
  const [pagination, setPagination] = useState();

  const _fetchTwitchAppAccess = useCallback(async () => {
    if (authTwitchAppAccess === undefined) {
      try {
        const result = await getTwitchAppAccessAuth();
        loginTwitchAppAccess(result);
      } catch (e) {}
    }
  }, [authTwitchAppAccess, loginTwitchAppAccess]);

  const _fetchTwitchClips = useCallback(async () => {
    if (authTwitchAppAccess) {
      try {
        setLoading(true);
        const result = await getClips(
          authTwitchAppAccess.access_token,
          authTwitchAppAccess.broadcaster.id,
          buildPayload(year, month)
        );
        setClips(result?.data);
        setPagination(result?.pagination?.cursor);
      } catch (e) {
        console.log("Erreur, pas de résultat", e);
      } finally {
        setLoading(false);
      }
    }
  }, [authTwitchAppAccess, year, month]);

  const _fetchTwitchGames = useCallback(async () => {
    if (authTwitchAppAccess) {
      try {
        const gamesId = clips?.reduce(
          (acc, { game_id }) =>
            acc.includes(game_id) || games[game_id] ? acc : [...acc, game_id],
          []
        );
        if (gamesId.length > 0) {
          const result = await getGames(authTwitchAppAccess.access_token, {
            id: gamesId,
          });

          const newGames = result?.data.reduce(
            (acc, { id, ...game }) => ({ ...acc, [id]: { ...game } }),
            {}
          );
          setGames({ ...games, ...newGames });
        }
      } catch (e) {
        console.log("Erreur, pas de résultat", e);
      } finally {
      }
    }
  }, [authTwitchAppAccess, clips, games]);

  useEffect(() => {
    _fetchTwitchAppAccess();
  }, [_fetchTwitchAppAccess]);

  useEffect(() => {
    _fetchTwitchClips();
  }, [_fetchTwitchClips]);

  useEffect(() => {
    _fetchTwitchGames();
  }, [_fetchTwitchGames]);

  const handleChange = (e) => {
    const [newYear, newMonth] = e.target.value.split("-");
    setYear(newYear);
    setMonth(newMonth);
  };

  const loadMore = async () => {
    if (authTwitchAppAccess) {
      try {
        setLoading(true);
        const result = await getClips(
          authTwitchAppAccess.access_token,
          authTwitchAppAccess.broadcaster.id,
          buildPayload(year, month, pagination)
        );
        setClips([...clips, ...result?.data]);
        setPagination(result?.pagination?.cursor);
      } catch (e) {
        console.log("Erreur, pas de résultat", e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card className={classes.container}>
      {authTwitchAppAccess?.access_token && (
        <CardContent className={classes.content}>
          <Grid container className={classes.commands}>
            <Grid item xs={3}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Année / Mois</InputLabel>
                <Select value={`${year}-${month}`} onChange={handleChange}>
                  {months.map((month, index) => {
                    const year = month.getFullYear();
                    const monthNb = month.getMonth();
                    return (
                      <MenuItem key={index} value={`${year}-${monthNb}`}>
                        {MONTHS_NAMES[monthNb]} {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {clips?.map((clip, index) => (
              <Grid item xs={12} sm={6} md={3} key={`clip-${index}`}>
                <Clip clip={clip} game={games[clip.game_id]} />
              </Grid>
            ))}
          </Grid>
          <Grid container className={classes.loadMore}>
            <Grid item xs={12} className={classes.loadMoreIn}>
              <Button
                variant="contained"
                color="primary"
                onClick={loadMore}
                disabled={!pagination}
                startIcon={!loading && <AddIcon />}
              >
                {loading ? (
                  <CircularProgress
                    size={25}
                    color="inherit"
                    className={classes.loader}
                  />
                ) : (
                  `Charger plus (${clips.length} clips)`
                )}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: theme.spacing(2),
    },
    content: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    commands: {
      marginBottom: 20,
    },
    loadMore: {
      marginTop: 20,
    },
    loadMoreIn: { display: "flex", justifyContent: "center" },
    loader: { margin: "0 35px" },
  };
});
