import React, { useState } from "react";
import { useAuth } from "./hooks/useAuth";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  loginRoot: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardMedia: {
    backgroundColor: "#f7f9fc",
    objectFit: "contain",
    width: "100%",
    padding: "5% 20%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

function Login(props) {
  const classes = useStyles();
  const { login } = useAuth();

  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);

  const toggleDisplay = (e) => {
    setDisplayPassword(!displayPassword);
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card classes={{ root: classes.card }}>
        <CardContent>
          <form className={classes.form} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label="Votre identifiant"
              name="login"
              autoComplete="login"
              autoFocus
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Votre mot de passe"
              type={displayPassword ? "text" : "password"}
              autoComplete="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleDisplay}>
                      {displayPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              fullWidth
              classes={{ root: classes.loginRoot }}
              onClick={login(id, password)}
              color="primary"
              variant="contained"
            >
              Connexion
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Login;
