const resultHandler = (result) => {
  return result.status === 200 ? result.json() : Promise.reject(result);
};
const errorHandler = async (error) => {
  const _error = await error.json();
  return Promise.reject(_error);
};

const buildQueryStringForObject = (key, object) => {
  return Object.entries(object)
    .filter(([k, v]) => v !== undefined)
    .map(([k, v]) => `${key}[${k}]=${encodeURIComponent(v)}`)
    .join("&");
};

const buildQueryString = (params) => {
  return Object.keys(params)
    .filter((k) => !!params[k])
    .map((k) =>
      typeof params[k] === "object"
        ? buildQueryStringForObject(k, params[k])
        : `${k}=${encodeURIComponent(params[k])}`
    )
    .join("&");
};

export const fetcher =
  (method) =>
  async (_url, payload, headers = {}) => {
    const qs = method === "GET" && payload ? buildQueryString(payload) : "";

    const url = qs.length === 0 ? _url : `${_url}?${qs}`;

    return fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        ...headers,
      },
      body:
        method !== "GET" && payload !== undefined
          ? JSON.stringify(payload)
          : undefined,
    })
      .then(resultHandler)
      .catch(errorHandler);
  };

export const get = fetcher("GET");
export const post = fetcher("POST");
