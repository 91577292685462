import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import Note from "./Note";

export default function Participant({ participant, position, solo }) {
  const classes = useStyles();

  return (
    <div className={classes.currentParticipant}>
      <div>
        <h2 className={classes.title}>Notation actuelle</h2>
        <Divider className={classes.divider} />
      </div>
      <div className={classes.gamertag}>{participant.gamertag}</div>
      {!solo && (
        <div className={classes.position}>
          {position}{" "}
          <sup className={classes.sup}>{position === 1 ? "er" : "ème"}</sup>
        </div>
      )}
      <Grid container className={classes.notes}>
        <Grid item xs={12} className={classes.chat}>
          <Note title="Note du chat" note={participant.noteViewers} />
        </Grid>
        <Grid item xs={6} className={classes.jury}>
          <Note title="Note du jury" note={participant.noteJury} />
        </Grid>
        <Grid item xs={6} className={classes.jack}>
          <Note title="Note de Jackdarius" note={participant.noteJack} />
        </Grid>
      </Grid>
      <div className={classes.bonus}>
        {participant.bonus && `+${participant.bonus} points bonus`}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    currentParticipant: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      width: "100%",
      marginTop: theme.spacing(1),
    },
    title: {
      margin: 0,
      fontSize: 30,
      color: "#aba491",
      textShadow:
        "-1px 0 5px #4c4a46, 0 1px 5px #4c4a46, 1px 0 5px #4c4a46, 0 -1px 5px #4c4a46",
    },
    divider: {
      background: `100%/100% no-repeat url(${require(`../assets/barre-3.png`)})`,
      height: 5,
      margin: theme.spacing(0.5, 0),
    },
    position: {
      flex: "1 1 auto",
      fontSize: 50,
      color: "#e7e3d2",
    },
    sup: {
      fontSize: 20,
      fontFamily: "roboto",
    },
    gamertag: { fontSize: 50, color: "#e7e3d2", flex: "1 1 auto" },
    bonus: {
      fontSize: 35,
      marginBottom: theme.spacing(3),
      minHeight: 42,
      color: "#f1ad31",
    },
    notes: {
      display: "flex",
      flex: "1 1 auto",
    },
    chat: {
      display: "flex",
      justifyContent: "center",
    },
    jury: {
      display: "flex",
      justifyContent: "center",
    },
    jack: {
      display: "flex",
      justifyContent: "center",
    },
  };
});
