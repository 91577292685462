import React, { useState, useContext } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import makeStyles from "@material-ui/styles/makeStyles";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";

const SnackbarContext = React.createContext();

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
}));

export const SnackbarProvider = (props) => {
  const { children } = props;
  const classes = useStyles();

  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [autoHide, setAutoHide] = useState(4000);
  const [open, setOpen] = useState(false);

  const openSnackbar = (
    messageOverride,
    statusOverride,
    autoHideOverride = 4000
  ) => {
    setMessage(messageOverride);
    setStatus(statusOverride);
    setAutoHide(autoHideOverride);
    setOpen(true);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={autoHide}
        onClose={closeSnackbar}
        transitionDuration={200}
      >
        <SnackbarContent
          className={status && classes[status]}
          message={<div>{message}</div>}
        />
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  if (!SnackbarContext) {
    throw new Error(`useSnackbar must be used within a SnackbarProvider`);
  }
  const { openSnackbar, closeSnackbar } = useContext(SnackbarContext);

  return { openSnackbar, closeSnackbar };
};
