import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import format from "date-fns/format";

export default function Clip({ clip, game }) {
  const classes = useStyles();
  const {
    creator_name,
    duration,
    thumbnail_url,
    title,
    view_count,
    url,
    created_at,
  } = clip;

  const openBlank = () => {
    window.open(url, "_blank");
  };

  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  return (
    <Box className={classes.clip} onClick={openBlank}>
      <Box className={classes.clipImg}>
        <img
          style={{ width: "100%" }}
          alt={title}
          src={thumbnail_url}
          loading="lazy"
        />
        <Box className={classes.clipDuration}>
          <Typography className={classes.clipDurationText}>
            {minutes}:{seconds < 10 && "0"}
            {seconds}
          </Typography>
        </Box>
        <Box className={classes.clipView}>
          <Typography className={classes.clipViewText}>
            {view_count} vues
          </Typography>
        </Box>
      </Box>
      <Box className={classes.clipText}>
        <Box className={classes.clipGame}>
          <img
            title={game?.name}
            alt={game?.name}
            src={game?.box_art_url
              .replace("{width}", "40")
              .replace("{height}", "52")}
            loading="lazy"
          />
        </Box>
        <Box className={classes.clipInfo}>
          <Typography className={classes.clipTitle}>{title}</Typography>
          <Box className={classes.clipCreator}>
            Crée par{" "}
            <Typography className={classes.clipCreatorName}>
              {creator_name}
            </Typography>
            , le{" "}
            <Typography className={classes.clipDateText}>
              {format(new Date(created_at), "dd/MM/yyyy")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    clip: {
      cursor: "pointer",
      "&:hover": {
        opacity: 0.8,
      },
    },
    clipImg: { position: "relative" },
    clipDuration: {
      position: "absolute",
      top: 0,
      left: 0,
      padding: theme.spacing(1, 1),
    },
    clipDurationText: {
      padding: "2px 4px",
      background: "rgba(0, 0, 0, 0.6)",
      color: "#fff",
      fontSize: 12,
    },
    clipView: {
      position: "absolute",
      bottom: 0,
      left: 0,
      padding: theme.spacing(1, 1),
    },
    clipViewText: {
      padding: "2px 4px",
      background: "rgba(0, 0, 0, 0.6)",
      color: "#fff",
      fontSize: 12,
    },
    clipText: {
      display: "flex",
      flexDirection: "row",
      background: "rgba(0, 0, 0, 0.1)",
    },
    clipGame: { marginRight: 5 },
    clipInfo: { overflow: "hidden" },
    clipTitle: {
      fontWeight: 700,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    clipViewTitle: { fontWeight: 700 },
    clipCreator: { fontSize: 12 },
    clipCreatorName: {
      display: "inline",
      color: "#9147ff",
      fontSize: 12,
      fontWeight: 700,
    },
    clipDate: { fontSize: 12 },
    clipDateText: { display: "inline", fontSize: 12, fontWeight: 700 },
  };
});
