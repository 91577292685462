import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const lvlHead = "lvl-head.png";
const points = "point-au-lait.png";
export default function Level({
  currentLvl,
  currentXp,
  index,
  level,
  full,
  isSolo,
  maxLevel,
  animateXp,
  modifiedXp,
  animationCallback,
  className,
}) {
  const { label, img, free, subs } = level;
  const classes = useStyles();
  const lvlOk = currentLvl > index;
  const isCurrent = currentLvl === index;

  useEffect(() => {
    if (animateXp) {
      setTimeout(animationCallback, 2500);
    }
  }, [animateXp, animationCallback]);

  return (
    <div
      className={`${className} ${classes.container}`}
      style={
        isSolo &&
        (maxLevel && currentLvl === index + 1
          ? {}
          : currentLvl !== index
          ? { position: "absolute", opacity: 0 }
          : {})
      }
    >
      <div
        className={`${classes.lvl} ${isCurrent ? classes.currentLvl : ""}`}
        style={{ zIndex: 1 }}
      >
        <img
          className={`${classes.img} ${currentLvl < index && classes.opacity}`}
          src={require(`../../../public/img/passxpv2/${lvlHead}`)}
          alt={`level${label}`}
        />

        <span className={classes.lvlTxt}>{label}</span>
      </div>
      {full && (
        <>
          <div
            className={`${classes.rewards} ${free ? classes.free : ""} ${
              isCurrent ? classes.current : ""
            }`}
          >
            {free && (
              <>
                <div
                  className={`${classes.reward} ${
                    !lvlOk && classes.hideReward
                  }`}
                >
                  {img ? (
                    <img
                      className={classes.imgReward}
                      src={require(`../../../public/img/passxpv2/NIVEAU ${label}/follower-${label}.png`)}
                      alt={`free${label}`}
                    />
                  ) : (
                    free.label
                  )}
                  {free.points && (
                    <div
                      className={`${classes.points} ${
                        !lvlOk && classes.hidePoints
                      }`}
                    >
                      <span className={classes.pointsTxt}>+{free.points}</span>
                      <img
                        src={require(`../../../public/img/passxpv2/${points}`)}
                        alt={`points${label}`}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={`${classes.questionMark} ${
                    lvlOk && classes.hideQuestionMark
                  }`}
                >
                  ?
                </div>
              </>
            )}
          </div>
          <div
            className={`${classes.rewards} ${classes.subs} ${
              isCurrent ? classes.current : ""
            }`}
          >
            {subs && (
              <>
                <div
                  className={`${classes.reward} ${
                    !lvlOk && classes.hideReward
                  }`}
                >
                  {img ? (
                    <img
                      className={classes.imgReward}
                      src={require(`../../../public/img/passxpv2/NIVEAU ${label}/escroc-crew-${label}.png`)}
                      alt={`subs${label}`}
                    />
                  ) : (
                    subs.label
                  )}
                  {subs.points && (
                    <div className={classes.points}>
                      <span className={classes.pointsTxt}>+{subs.points}</span>
                      <img
                        src={require(`../../../public/img/passxpv2/${points}`)}
                        alt={`points${label}`}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={`${classes.questionMark} ${
                    lvlOk && classes.hideQuestionMark
                  }`}
                >
                  ?
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

const backgroundFollower = "follower-lvl.png";
const backgroundSub = "sub-lvl.png";
const useStyles = makeStyles((theme) => {
  return {
    container: { marginRight: 2 },
    lvl: {
      position: "relative",
      height: 60,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.primary.main,
      fontWeight: "500",
    },
    lvlTxt: {
      position: "absolute",
      fontFamily: "tilting",
      fontSize: 40,
      color: "#ffffff",
    },
    rewards: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "tilting",
      fontSize: 20,
      borderTop: "3px solid transparent",
      borderBottom: "3px solid transparent",
    },
    reward: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 240ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    hideReward: {
      transform: "scale(0,0)",
    },
    imgReward: {
      zIndex: 2,
      width: "100%",
    },
    labelReward: {
      textAlign: "center",
    },
    progress: {
      position: "absolute",
      color: "#00C3DE",
    },
    free: {
      height: 237,
      width: 130,
      color: "white",
      background: `100%/100% no-repeat url(${require(`../../../public/img/passxpv2/${backgroundFollower}`)})`,
    },
    subs: {
      height: 122,
      width: 130,
      color: "white",
      background: `100%/100% no-repeat url(${require(`../../../public/img/passxpv2/${backgroundSub}`)})`,
    },
    opacity: {
      // opacity: 0.95,
      // filter: "grayscale(1)",
    },
    questionMark: {
      position: "absolute",
      top: "50%",
      transform: "translate(0, -50%)",
      fontSize: 60,
      transition: "opacity 240ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    hideQuestionMark: {
      opacity: 0,
    },
    xp: {
      position: "absolute",
      width: "100%",
      opacity: 1,
      transition: "opacity 240ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    animateXp: {
      position: "absolute",
      width: "100%",
      opacity: 0,
      transition: "opacity 240ms cubic-bezier(0.4, 0, 0.2, 1)",
      animationTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    },
    solo: {
      fontFamily: "tilting",
      fontSize: 28,
      textAlign: "center",
      color: "#00C3DE",
      textShadow:
        "-1px 0 #083138, 0 1px #083138, 1px 0 #083138, 0 -1px #083138",
      zIndex: 10,
    },
    currentLvl: {
      filter:
        "drop-shadow(2px 2px white) drop-shadow(-2px -2px white) drop-shadow(2px -2px white) drop-shadow(-2px 2px white)",
    },
    current: {
      zIndex: 10,
      outline: "3px solid white",
      outlineOffset: -0,
      borderRadius: 3,
    },
    points: {
      position: "absolute",
      bottom: 7,
      right: 7,
      display: "flex",
      fontSize: 24,
      zIndex: 20,
      opacity: 1,
      transition: "opacity 1240ms cubic-bezier(0.4, 0, 0.2, 1)",
    },
    hidePoints: {
      opacity: 0,
    },
    pointsTxt: { marginRight: 3, textShadow: "-1px -1px 3px #000000" },
  };
});
