import React from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useSnackbar } from "./useSnackbar";
import { authenticate } from "../../services/api";
import { getBroadcasterId } from "../../services/apiTwitch";

const AuthContext = React.createContext();

function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function AuthProvider(props) {
  const [auth, setAuth] = useLocalStorage("auth", false);
  const [authTwitch, setAuthTwitch] = useLocalStorage("authTwitch");
  const [authTwitchAppAccess, setAuthTwitchAppAccess] = useLocalStorage(
    "authTwitchAppAccess"
  );

  const { openSnackbar } = useSnackbar();

  const login = (id, password) => async () => {
    try {
      const result = await authenticate(id, password);
      openSnackbar(`Yo ${result.user}`);
      setAuth(true);
    } catch (e) {
      openSnackbar(
        "Identifiant/Mot de passe invalide. Veuillez réessayer",
        "error"
      );
      console.log(e);
    }
  };

  const loginTwitch = async (result) => {
    const { data: broadcasterInfo } = await getBroadcasterId(
      result.access_token
    );

    setAuthTwitch({ ...result, broadcaster: broadcasterInfo[0] });
  };

  const loginTwitchAppAccess = async (result) => {
    const { data: broadcasterInfo } = await getBroadcasterId(
      result.access_token,
      "jackdarius"
    );
    setAuthTwitchAppAccess({ ...result, broadcaster: broadcasterInfo[0] });
  };

  const logout = () => {
    setAuth(false);
    setAuthTwitch(undefined);
    setAuthTwitchAppAccess(undefined);
  };

  const logoutTwitch = () => {
    setAuthTwitch(undefined);
  };
  const logoutTwitchAppAccess = () => {
    setAuthTwitchAppAccess(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        auth,
        authTwitch,
        authTwitchAppAccess,
        loginTwitch,
        logoutTwitch,
        loginTwitchAppAccess,
        logoutTwitchAppAccess,
      }}
      {...props}
    />
  );
}

export { AuthProvider, useAuth };
