import React, { Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
} from "react-router-dom";
import { withConfiguration } from "../services/config";
import { useAuth } from "./hooks/useAuth";
import Login from "./Login";
import Clips from "./Clips";
import Statistiques from "./Statistiques";
import PassXP from "./PassXp";
import PassXP_v2 from "./PassXp_v2";
import PerfectPirate from "./PerfectPirate";
import JackADit from "./JackADit";

import makeStyles from "@material-ui/styles/makeStyles";
import Drawer from "@material-ui/core/Drawer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuIcon from "@material-ui/icons/Menu";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import LocalMoviesIcon from "@material-ui/icons/LocalMovies";
import { PassIcon, PirateIcon } from "./Icons";

const routes = [
  {
    label: "Statistiques",
    Icon: EqualizerIcon,
    path: "/statistiques",
    Component: Statistiques,
  },
  {
    label: "Clips Twitch",
    Icon: LocalMoviesIcon,
    path: "/clips-twitch",
    Component: Clips,
  },
  {
    label: "Escroc Pass",
    Icon: PassIcon,
    path: "/escroc-pass",
    Component: PassXP,
  },
  {
    label: "Escroc Pass v2",
    Icon: PassIcon,
    path: "/escroc-pass-v2",
    Component: PassXP_v2,
  },
  {
    label: "The Perfect Pirate",
    Icon: PirateIcon,
    path: "/the-perfect-pirate",
    Component: PerfectPirate,
  },
  {
    label: "Jack à Dit",
    Icon: EqualizerIcon,
    path: "/jack-a-dit",
    Component: JackADit,
  },
];

const buildTwitchConnectionLink = () =>
  withConfiguration(
    ({ twitch: twitchConfig }) =>
      `https://id.twitch.tv/oauth2/authorize?client_id=${twitchConfig.clientId}&redirect_uri=${twitchConfig.redirect}&response_type=code&scope=${twitchConfig.scope}` //&state=${secret}
  );

const initTitle = () => {
  return routes.find((route) => window.location.pathname.includes(route.path))
    ?.label;
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: 20,
    flex: "1 1 auto",
  },
  icons: {
    fill: theme.palette.primary.main,
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
  },
}));

export default function App() {
  const classes = useStyles();
  const { auth, authTwitch, logout, logoutTwitch } = useAuth();

  const [openDrawer, setOpenDrawer] = useState();
  const [title, setTitle] = useState(initTitle());

  const handleChangeNav = (route) => {
    setTitle(route.label);
  };
  return (
    <Router>
      <Suspense fallback={<CircularProgress />}>
        {!auth ? (
          <Login />
        ) : (
          <>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setOpenDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {title}
                </Typography>
                {authTwitch ? (
                  <Button color="inherit" onClick={logoutTwitch}>
                    Déconnexion Twitch
                  </Button>
                ) : (
                  <a href={buildTwitchConnectionLink()}>Connexion Twitch</a>
                )}
                <Button color="inherit" onClick={logout}>
                  Déconnexion
                </Button>
              </Toolbar>
            </AppBar>
            <Navigation
              open={openDrawer}
              setOpen={setOpenDrawer}
              handleChangeNav={handleChangeNav}
            />
            <Switch>
              {routes.map(({ path, Component }, index) => (
                <Route key={index} exact path={path} component={Component} />
              ))}
            </Switch>
          </>
        )}
      </Suspense>
    </Router>
  );
}

const Navigation = ({ open, setOpen, handleChangeNav }) => {
  const classes = useStyles();

  const changeNav = (route) => () => {
    handleChangeNav(route);
    setOpen(false);
  };
  return (
    <Drawer
      anchor={"left"}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <List>
        {routes.map(({ Icon, ...route }) => (
          <NavLink
            to={route.path}
            key={route.label}
            style={{ textDecoration: "inherit", color: "inherit" }}
            activeClassName={classes.active}
          >
            <ListItem button onClick={changeNav(route)}>
              <ListItemIcon>
                {Icon && <Icon className={classes.icons} />}
              </ListItemIcon>
              <ListItemText primary={route.label} />
            </ListItem>
          </NavLink>
        ))}
      </List>
    </Drawer>
  );
};
