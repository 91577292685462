import React from "react";
import useWebSocket from "react-use-websocket";
import { makeStyles } from "@material-ui/core/styles";
import { withConfiguration } from "../../services/config";
import { fetchJackADitDefis } from "../../services/api";
import Button from "./Button";

const LIMIT = 40;
const OFFSET = 30;
const HEIGHT = 50;

function fillArray(arr, result) {
  const clone = [...arr];
  while (clone.length > 0) {
    const i = Math.floor(Math.random() * clone.length);
    const item = clone.splice(i, 1)[0];
    result.push(item);
  }
}
function shuffle(arr) {
  if (arr && arr.length > 0) {
    let duplicate = Math.ceil(LIMIT / arr.length);
    const randomArr = [];

    while (duplicate > 0) {
      fillArray(arr, randomArr);
      duplicate--;
    }

    return randomArr;
  } else {
    return [];
  }
}

export default function SlotMachine({ code, defaultItems }) {
  const classes = useStyles();
  const didUnmount = React.useRef(false);
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    withConfiguration((config) => config["ws-jack-a-dit"]),
    {
      shouldReconnect: () => {
        return didUnmount.current === false;
      },
      retryOnError: true,
      reconnectAttempts: 60,
      reconnectInterval: 1000,
    }
  );
  const [values, setValues] = React.useState(defaultItems.join("\n"));
  const [items, setItems] = React.useState(defaultItems);
  const [usedItems, setUsedItems] = React.useState(shuffle(items));
  const [translateY, setTranslateY] = React.useState(
    `translateY(-${HEIGHT * usedItems.length + OFFSET}px)`
  );
  const [reseting, setReseting] = React.useState(false);

  React.useEffect(() => {
    if (reseting) {
      setReseting(false);
    }
  }, [reseting]);

  const _fetchItems = React.useCallback(async () => {
    const items = await fetchJackADitDefis(code);

    if (items && items.length > 0) {
      setValues(items.join("\n"));
      setItems(items);

      const usedItems = shuffle(items);
      const size = HEIGHT * usedItems.length + OFFSET;
      setTranslateY(`translateY(-${size}px)`);
      setReseting(true);
      setUsedItems(usedItems);
    }
  }, [code]);

  React.useEffect(() => {
    _fetchItems();
    return () => {
      didUnmount.current = true;
    };
  }, [_fetchItems]);

  const spin = React.useCallback(() => {
    setTranslateY("translateY(0px)");
  }, []);

  React.useEffect(() => {
    if (lastJsonMessage) {
      try {
        if (lastJsonMessage) {
          if (lastJsonMessage.code === code) {
            if (
              lastJsonMessage.action === "save" ||
              lastJsonMessage.action === "reset"
            ) {
              _fetchItems();
            } else if (lastJsonMessage.action === "spin") {
              spin();
            }
          }
        }
      } catch (e) {
        console.log("error during websocket reception", e);
      }
    }
  }, [lastJsonMessage, code, _fetchItems, spin]);

  const saveValues = async () => {
    const items = values.split("\n");
    sendJsonMessage({ code, items, action: "save" });
  };

  const spinWs = () => {
    sendJsonMessage({ action: "spin", code });
  };
  const resetWs = () => {
    sendJsonMessage({ action: "reset", code });
  };

  return (
    <div className={classes.app}>
      <div className={classes.doors}>
        <div className={classes.door}>
          {!reseting && (
            <div className={classes.boxes} style={{ transform: translateY }}>
              {usedItems.map((item, index) => (
                <div key={index} className={classes.box}>
                  {item}
                </div>
              ))}
              <div className={`${classes.box} ${classes.interro}`}>
                Prochain défi
                <span role="img" aria-label="first element">
                  ❓
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={classes.buttons}>
        <Button onClick={spinWs} label="Play" color="primary" />
        <Button onClick={resetWs} label="Reset" />
      </div>
      <div className={classes.savezone}>
        <textarea
          className={classes.textarea}
          type="text"
          value={values}
          onChange={(e) => setValues(e.target.value)}
        />
        <Button onClick={saveValues} label="Save & Reset" color="primary" />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    app: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
    },
    doors: {
      display: "flex",
    },
    door: {
      background: "blue",
      width: 400,
      height: HEIGHT,
      overflow: "hidden",
      paddingTop: OFFSET,
    },
    boxes: {
      transition: "transform 10s cubic-bezier(0.4, 0, 0.2, 1)",
    },
    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      width: 400,
      height: HEIGHT,
      fontSize: "2rem",
      fontFamily: "avenir",
      textShadow: "2px 2px 2px #000000",
    },
    interro: {
      height: HEIGHT + OFFSET,
      alignItems: "flex-end",
    },
    buttons: {
      display: "flex",
      gap: "20px",
      margin: "20px 0",
    },
    button: {
      cursor: "pointer",
      fontSize: "1.2rem",
      margin: "0 0.2rem",
      border: "none",
      borderRadius: 5,
      padding: "10px 15px",
    },
    info: {
      position: "fixed",
      bottom: 0,
      width: "100%",
      textAlign: "center",
    },
    savezone: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "12px",
    },
    textarea: {
      width: 400,
      height: 200,
    },
    savebutton: {
      width: "auto",
    },
  };
});
